@font-face {
  font-family: 'Helvetica neue';
  src: url('/src/assets/fonts/helveticaneue.eot'); /* IE9 Compat Modes */
  src: url('/src/assets/fonts/helveticaneue.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/src/assets/fonts/helveticaneue.woff') format('woff'),
    /* Modern Browsers */ url('/src/assets/fonts/helveticaneue.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/src/assets/fonts/helveticaneue.svg#HelveticaNeue') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}

:root {
  --primary-color: #a5dacf;
  --secondary-color: #a5dacf;
  --green-color: #537f6e;
  --available-color: #a5dacf;
  --light: #004a33;
  --color-sold: #61615c;
  --grey: #6b7280;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
